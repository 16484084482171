
.srnoappname {
    display: flex;
    justify-content:space-between;
    align-items: center;
    /* border: 2px solid black; */
  }
  
  .emaildashb {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* gap: 5px; */
    /* border: 2px solid black; */
  }
  
  .emailflex {
    flex: 1;
    align-items: center;
    padding: 5px;
    /* border: 2px solid black; */
  }
  
  .scndflex {
    flex: 1;
    align-items: center;
    justify-content: center;
    /* margin-top: 1.5rem; */
    padding: 20px;
    /* margin-right: 300px;
    margin-top: 10px; */
    /* border: 2px solid pink; */
  }
  
  .inputflex {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content:right;
    align-items: flex-start;
    padding: 20px;
    padding-bottom: 5rem;
    /* margin-right: 260px;
    margin-bottom: 5.5rem; */
    /* border: 2px solid violet; */
  }
  
  .subbttn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    padding: 50px;
    margin-right: -50px;
    margin-left: -50px;
  }
  
  .emailGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, auto); */
    grid-gap: 10px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
  }
  
  .emailGriditem {
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    /* border: 2px solid violet; */
  }
  
  .vendorcodelabel {
    margin-bottom: 5px;
    /* color: #012386; */
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1.2rem;
  }
  
  .label2 {
    margin-bottom: 5px;
    /* color: #012386; */
    font-weight: 600;
    font-size: 16px;
  }
  
  .input_dash_wrapper{
    display: flex;
    margin-top: 0.8rem;
  }
  
  .astspan {
    color: red;
    position: absolute;
    padding-left: 5px;
    padding-bottom: 5px;
  }
  
  .addemlbttn {
    background-color: grey;
    border: 2px solid black;
  }

.finalbttn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.enteremailtitle{
  display: flex;
  justify-content: space-between;
  align-items:end;
  margin-bottom: 15px;
  margin-top: 15px;
  /* border: 2px solid yellow; */
}

