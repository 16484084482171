.filtersearchsort {
    display: flex;
    flex-direction: column; 
    align-items: center;
    margin-bottom: 20px;
}

.heading {
    margin-top: 5px;
    /* font-size: 24px; */
    text-align: center; 
}

.componenetssrch {
    display: flex;
    flex-direction: column; 
    justify-content: space-evenly;
    align-items: center; 
}

.tblstyle {
    border-collapse: collapse; 
    width: 100%;
    font-family: Roboto;
    border: 0.01rem solid #E7E7E7;
}

.tblstyle th,
.tblstyle td {
    
    padding: 12px;
    text-align: left;
}

.tblstyle th {
    background-color: #EAEFFF;
;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}


@media screen and (min-width: 1024px) {
    .filtersearchsort {
        flex-direction: row;
        justify-content: space-between; 
    }

    .componenetssrch {
        flex-direction: row;
        align-items: center;
    }

    .heading {
        /* font-size: 32px; */
    } 

    .tblstyle th,
    .tblstyle td {
        padding: 16px; 
    }
}
