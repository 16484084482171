/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;
}

.col-6 .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row .col-3 .v1,
.row .col-3 .v3 {
  color: darkblue;
  font-weight: bold;
}

.row {
  flex-direction: row;
}

.Hdiv1 {
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: #012386;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 900;
  line-height: 26.5px; /* 189.286% */
  letter-spacing: -0.35px;
  text-transform: uppercase;
}

.Adiv1 {
  width: 100%;
  height: 10vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url(./images/first.png), lightgray 50% / cover repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.5px; /*126.19%*/
  letter-spacing: -0.525px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Adiv2 {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid #e6e9f0;
  background: linear-gradient(180deg, #f0f4ff 0%, rgba(240, 244, 255, 0) 100%);
}

/* .Hdiv2 {
  border-radius: 27px;
border: 1px solid #FFF;
background: url(<path-to-image>), lightgray 50% / cover no-repeat;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
} */

.Ediv1 {
  display: flex;
  /* position: fixed; */
  width: 100%;
  height: 100%;
  border: 1px solid #e6e9f0;
  background: linear-gradient(
    180deg,
    #f5f5fa 0%,
    rgba(255, 255, 255, 0.46) 100%
  );
}

.EP1 {
  color: #012386;
  display: flex;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.5px; /* 110.417% */
  letter-spacing: -0.6px;
}

.EE1 {
  color: #343536;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.5px; /* 189.286% */
  letter-spacing: -0.35px;
}

.button-container {
  display: flex;
  position: relative;
  transform: translateY(-50%);
  width: 200%;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background: #012386;
  border: none;
  outline: none;
  overflow: hidden;

  font-size: 100%;
  font-weight: 500;
  cursor: pointer;
}

.button_text {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
  color: #fff;
  height: 100%;
}

.page-container {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
  align-items: flex-end;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #383838;
  padding: 3px 15px;
  border-radius: 3px;
}

ul {
  margin: 10px 20px;
}

ul li {
  display: inline-block;
  margin: 0 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 35px;
  cursor: pointer;
}

ul li.active {
  color: #fff;
  background-image: linear-gradient(#ff4568, #ff4568);
  background-repeat: no-repeat;
}
.btn1,
.btn2 {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #383838;
  background: transparent;
  outline: none;
  border: none;
}

.btn1 img {
  width: 24px;
  margin-right: 15px;
}

.btn2 img {
  width: 24px;
  margin-right: 15px;
  transform: rotate(180deg);
}

.button:hover {
  background: darkblue;
}

.button:active {
  background: #580599;
}

.register {
  display: flex;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  background-color: white;
  max-height: 100vh;
  overflow: hidden;
}

.left-col {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.left-col img {
  width: 100%;
  height: 100vh;
  object-fit: contain;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.right-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 3em 0;
}

.centered {
  text-align: center;
}

#form {
  max-width: 320px;
  width: 100%;
  margin: 2em auto;
}

#form > input,
#form > .btn {
  border: 1px solid #e9ecef;
  padding: 0.9em 1em;
}

#form > input:focus {
  outline: none;
}

#form > .btn {
  background-color: #003049;
  color: #e9ecef;
  font-size: 1em;
  cursor: pointer;
}

.flex {
  display: flex;
  gap: 1em;
}

.flex-col {
  flex-direction: column;
}

@media screen and (min-width: 860px) {
  .register .col-2 img {
    display: initial;
  }
}
