
.iServeflex {
  display: flex;
  justify-content:space-between;
  align-items: center;
  /* border: 2px solid red; */
}

.emaildashb {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* gap: 5px; */
  /* border: 2px solid green; */
}

.emailflex {
  flex: 1;
  align-items: center;
  padding: 5px;
  /* border: 2px solid red; */
}

.scndflex {
  flex: 1;
  align-items: center;
  padding: 20px;
  /* margin-top: 10px; */
  /* border: 2px solid navy; */
}

.iServe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  /* border: 2px solid violet; */
}

.subbttn {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    padding: 50px;
    margin-right: -50px;
    margin-left: -50px;
    /* border: 2px solid black; */
}

.emailGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, auto); */
  grid-gap: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}

.emailGriditem {
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  /* border: 2px solid violet; */
}

.label1 {
  margin-bottom: 5px;
  /* color: #012386; */
  font-weight: 600;
  font-size: 16px;
}

.label2 {
  margin-bottom: 5px;
  /* color: #012386; */
  font-weight: 600;
  font-size: 16px;
}

.input_dash_wrapper{
  display: flex;
  flex-wrap:wrap ;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  /* border: 2px solid black; */
}

.astspan {
  color: red;
  position: absolute;
  padding-left: 5px;
  padding-bottom: 5px;
}

.addemlbttn {
  background-color: grey;
  border: 2px solid black;
}

.finalbttn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.enteremailtitle{
  display: flex;
  justify-content: space-between;
  align-items:end;
  margin-bottom: 15px;
  margin-top: 15px;
  /* border: 2px solid yellow; */
}