.filtersearchsort {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px; 
    align-items: center;
}

.heading {
    margin-top: 10px; 
    margin-bottom: 10px; 
    /* font-size: 24px; */
    text-align: center;
}

.componenetssrch {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-evenly; 
    align-items: center;
}

.componenetssrch > div {
    margin-bottom: 10px; 
}

/* Styles for table */
.tblstyle {
    border-collapse: collapse;
    width: 100%;
    font-family: Roboto;
    border: 0.01rem solid #E7E7E7;
}

.tblstyle th,
.tblstyle td {
    padding: 12px; 
    text-align: left;
}

.tblstyle th {
    background-color: #EAEFFF;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

@media screen and (min-width: 768px) {
    .filtersearchsort {
        flex-direction: row; 
        justify-content: space-between; 
    }

    .componenetssrch > div {
        margin-bottom: 0; 
    }

    .heading {
        /* font-size: 32px; */
    }
}

