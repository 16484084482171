
.srnoappname {
    display: flex;
    justify-content:space-between;
    align-items: center;
    /*border: 2px solid black;*/
  }
  
  .emaildashb {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* gap: 5px; */
    /* border: 2px solid black; */
  }
  
  .emailflex {
    align-items: center;
    padding: 5px;
    /* border: 2px solid black; */
  }
  
  .scndflex {
    align-items: center;
    padding: 8px;
    margin-right: 30px;
    margin-top: 10px;
    /* border: 2px solid black; */
  }
  
  .inputflex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    /*border: 2px solid black;*/
  }
  
  .subbttn {
    display: flex;
      flex-direction: row;
      justify-content: space-between;
      /* align-items: center; */
      padding: 50px;
      margin-right: -50px;
      margin-left: -50px;
  }
  
  .emailGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, auto); */
    grid-gap: 10px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
  }
  
  .emailGriditem {
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
  }
  
  .label1 {
    margin-bottom: 5px;
    /* color: #012386; */
    font-weight: 600;
    font-size: 16px;
  }
  
  .label2 {
    margin-bottom: 5px;
    /* color: #012386; */
    font-weight: 600;
    font-size: 16px;
  }
  
  .input_dash_wrapper{
    display: flex;
    flex-wrap:wrap ;
    width: 500px;
    /* border: 2px solid black; */
  }
  
  .astspan {
    color: red;
    position: absolute;
    padding-left: 5px;
    padding-bottom: 5px;
  }
  
  .addemlbttn {
    background-color: grey;
    border: 2px solid black;
  }
  
  .finalbttn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }