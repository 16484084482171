.buttondisplay{
    display: flex;
    justify-content: space-around;
    padding: 0.35rem;
}

.actionbutton{
    background-color: #171C8F; 
    font-size: 0.7rem; 
    padding: .25rem .5rem;
}