@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.srnoappname {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 5px;
    margin-top: 2rem;
    /* border: 2px solid black; */
  }
  
  .emaildashb {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* gap: 5px; */
    /* border: 2px solid black; */
  }
  
  .emailflex {
    flex: 1;
    align-items: center;
    padding: 10px;
    /* border: 2px solid pink; */
  }
  
  .scndflex {
    flex: 1;
    align-items: center;
    padding: 20px;
    /* margin-right: 30px;
    margin-top: 10px;
    margin-left: 12rem ; */
    width: 50%;
    /* border: 2px solid black; */
  }
  
  .inputflex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    /* border: 2px solid black; */
  }
  
  .subbttn {
    display: flex;
      flex-direction: row;
      justify-content: flex-end;
      /* align-items: center; */
      padding: 50px;
      

  }
  
  .emailGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, auto); */
    grid-gap: 10px;
    
    border-radius: 5px;
    /* margin-top: 10px; */
    /* padding: 10px; */
  }
  
  .emailGriditem {
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
  }
  
  .label1 {
    margin-bottom: 5px;
    /* color: #012386; */
    font-weight: 600;
    font-size: 16px;
  }
  
  .label2 {
    margin-bottom: 5px;
    /* color: #012386; */
    font-weight: 600;
    font-size: 16px;
  }
  
  .input_dash_wrapper{
    display: flex;
    flex-wrap:wrap ;
    width: 1000px;
    border: 2px solid black;
  }
  
  .astspan {
    color: red;
    position: absolute;
    padding-left: 5px;
    padding-bottom: 5px;
  }
  
  .addemlbttn {
    background-color: grey;
    border: 2px solid black;
  }
  
  .finalbttn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
 .optgroup,.select,.option{
  margin: 0;
  font-family: "roboto" !important;
  font-size: inherit;
  line-height: inherit;
 }